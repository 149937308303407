<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <span>
          <b-img
            :src="appLogoImage"
            alt="logo"
            v-bind="mainProps"
          />
        </span>
        <h2 class="brand-text text-primary ml-1">
          AILex Tech
        </h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Register V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Register-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title class="mb-1">
            {{ $t('Activate.title') }}
          </b-card-title>
          <b-card-text class="mb-2">
            {{ $t('Activate.cardText') }}
          </b-card-text>

          <!-- form -->
          <validation-observer
            ref="registerForm"
            #default="{invalid}"
          >
            <b-form
              class="auth-register-form mt-2"
              @submit.prevent="activate"
            >
              <!-- First Name -->
              <b-form-group
                :label="$t('Activate.form.firstNameLabel')"
                label-for="register-firstname"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Firstname"
                  vid="firstname"
                  rules="required"
                >
                  <b-form-input
                    id="register-firstname"
                    v-model="firstname"
                    name="register-firstname"
                    :state="errors.length > 0 ? false:null"
                    placeholder=""
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- Last Name -->
              <b-form-group
                :label="$t('Activate.form.lastNameLabel')"
                label-for="register-lastname"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Lastname"
                  vid="lastname"
                  rules="required"
                >
                  <b-form-input
                    id="register-lastname"
                    v-model="lastname"
                    name="register-lastname"
                    :state="errors.length > 0 ? false:null"
                    placeholder=""
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- email -->
              <b-form-group
                :label="$t('Activate.form.emailLabel')"
                label-for="register-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  vid="email"
                  rules="required|email"
                >
                  <b-form-input
                    id="register-email"
                    v-model="userEmail"
                    name="register-email"
                    :state="errors.length > 0 ? false:null"
                    placeholder=""
                    :disabled="true"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- password -->
              <b-form-group
                label-for="register-password"
                :label="$t('Activate.form.passLabel')"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="password"
                  rules="required|password"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="register-password"
                      v-model="password"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      :state="errors.length > 0 ? false:null"
                      name="register-password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordToggleIcon"
                        class="cursor-pointer"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group v-if="!testingFlag">
                <b-form-checkbox
                  id="register-privacy-policy"
                  v-model="status"
                  name="checkbox-1"
                >
                  I agree to
                  <b-link>privacy policy & terms</b-link>
                </b-form-checkbox>
              </b-form-group>

              <b-button
                variant="primary"
                block
                type="submit"
                :disabled="invalid"
                class="mr-1"
              >
                <b-spinner
                  v-show="fetching"
                  small
                  type="grow"
                />
                {{ submitButton }}
              </b-button>
            </b-form>
          </validation-observer>

          <p class="text-center mt-2">
            <span>{{ $t('Activate.login.cardText') }}</span>
            <b-link :to="{name:'auth-login'}">
              <span>&nbsp;{{ $t('Activate.login.buttonText') }}</span>
            </b-link>
          </p>

        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BCardText, BCardTitle, BImg, BForm, BButton, VBTooltip, BFormCheckbox, BSpinner,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import { required, email, password as password_rule } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
// import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import { $themeConfig } from '@themeConfig'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BSpinner,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  setup() {
    // App Name
    const { appName, appLogoImage } = $themeConfig.app
    return {
      appName,
      appLogoImage,
    }
  },
  data() {
    return {
      mainProps: {
        width: 30,
        height: 30,
      },
      status: '',
      firstname: '',
      lastname: '',
      password: '',
      userEmail: '',
      sideImg: require('@/assets/images/pages/register-v2.svg'),

      // validation rules
      required,
      fetching: true,
      submitButton: this.$i18n.t('Activate.form.buttonTexts.fetching'),
      ticket: '',
      email,
      password_rule,
      testingFlag: true,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  created() {
    this.ticket = this.$route.query.ticket
    useJwt.fetchTicketData({
      params: {
        ticket: this.$route.query.ticket,
      },
    })
      .then(response => {
        this.firstname = response.data.first_name
        this.lastname = response.data.last_name
        this.userEmail = response.data.email
        this.fetching = false
        this.submitButton = this.$i18n.t('Activate.form.buttonTexts.activate')
      })
      .catch(() => {
        this.$router.push('/login')
        // this.$refs.registerForm.setErrors(error.response)
      })
  },
  methods: {
    activate() {
      this.$refs.registerForm.validate().then(success => {
        if (success) {
          useJwt.activate({
            activation_ticket: this.ticket,
            first_name: this.firstname,
            last_name: this.lastname,
            password: this.password,
          })
            .then(response => {
              // console.log(response)
              useJwt.setToken(response.data.accessToken)
              // useJwt.setRefreshToken(response.data.refreshToken)
              let userData = response.data
              useJwt.getUserInfo().then(userInfo => {
                userData = { ...userData, ...userInfo.data }
                const translatedUserRole = this.$i18n.t(`Users.roles.${userData.role.toLowerCase()}`)
                localStorage.setItem('userData', JSON.stringify(userData))
                this.$ability.update(userData.abilities)
                this.$router.replace(getHomeRouteForLoggedInUser(userData.role))
                  .then(() => {
                    this.$toast({
                      component: ToastificationContent,
                      position: 'top-right',
                      props: {
                        title: this.$i18n.t('Toast.titles.activate', { name: userData.display_name || userData.first_name }),
                        icon: 'CoffeIcon',
                        variant: 'success',
                        text: this.$i18n.t('Toast.texts.successActivate', { role: translatedUserRole }),
                      },
                    })
                  })
              }).catch(error => {
                this.$refs.registerForm.setErrors({ password: error.response.data.error })
              })
            })
            .catch(error => {
              this.$refs.registerForm.setErrors({ password: error.response.data.error })
            })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
